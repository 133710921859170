@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  a:hover {
    @apply text-blue-600;
  }
  a.style {
    @apply underline;
  }

  body {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 400px) 1fr;
    grid-template-rows: 1fr auto 1fr auto;
    height: 100vh;
    width: 100vw;
  }

  main {
    grid-column: 2;
    grid-row: 2;
  }

  footer {
    grid-column: 2;
    grid-row: 4;
  }
}
